export const autoCompleteData = [
    "Steel Delta", "Panthera onca", "Jungle Spray", "First Class", "Jet Set", "Emerald Pinstripe", "Baroque Purple", "Predator", "Safari Mesh", "Wild Lotus",
    "Black Laminate", "Safety Net", "Gold Arabesque", "Green Laminate", "Hydroponic", "X-Ray", "Case Hardened", "Red Laminate", "Fire Serpent", "Blue Laminate",
    "Redline", "Vulcan", "Jaguar", "Wasteland Rebel", "Cartel", "Elite Build", "Aquamarine Revenge", "Frontside Misty", "Point Disarray", "Fuel Injector",
    "Neon Revolution", "Bloodsport", "Orbit Mk01", "The Empress", "Neon Rider", "Asiimov", "Uncharted", "Rat Rod", "Phantom Disruptor", "Legion of Anubis", "Slate",
    "Leet Museo", "Nightwish", "Ice Coaled", "Head Shot", "Eye of Horus", "Jungle Tiger", "Desert Storm", "Tornado", "Modern Hunter", "Radiation Hazard", "Dark Blossom",
    "Urban DDPAT", "Converter", "Mainframe", "Red DDPAT", "The Coalition", "Poseidon", "Daybreak", "Global Offensive", "Faded Zebra", "Zirka", "Desert-Strike",
    "Howl", "Bullet Rain", "Griffin", "龍王 (Dragon King)", "Evil Daimyo", "Royal Paladin", "The Battlestar", "Desolate Space", "Buzz Kill", "Hellfire",
    "Neo-Noir", "Magnesium", "The Emperor", "Tooth Fairy", "Cyber Security", "In Living Color", "Spider Lily", "Poly Mag", "Temukau", "Mud-Spec", "Welcome to the Jungle",
    "Knight", "VariCamo", "Boreal Forest", "Master Piece", "Nitro", "Control Panel", "Fizzy POP", "Imminent Danger", "Hot Rod", "Icarus Fell", "Moss Quartz",
    "Blue Phosphor", "Dark Water", "Bright Water", "Blood Tiger", "Guardian", "Atomic Alloy", "Cyrex", "Basilisk", "Hyper Beast", "Golden Coil", "Chantico's Fire",
    "Mecha Industries", "Flashback", "Decimator", "Briefing", "Leaded Glass", "Nightmare", "Player Two", "Printstream", "Night Terror", "Emphorosaur-S", "Duality",
    "Black Nile", "The Prince", "Dragon Lore", "Pit Viper", "Pink DDPAT", "Acheron", "Desert Hydra", "POP AWP", "Medusa", "Gungnir", "Fade", "Silk Tiger",
    "Lightning Strike", "BOOM", "Graphite", "Electric Hive", "Corticera", "Man-o'-war", "Worm God", "Phobos", "Fever Dream",
    "Oni Taiji", "Mortis", "PAW", "Atheris", "Wildfire", "Containment Breach", "Capillary", "Exoskeleton", "Chromatic Aberration", "Pilot", "Meteorite",
    "Hand Cannon", "Blaze", "Mudder", "Urban Rubble", "Fennec Fox", "Sputnik", "Night", "Midnight Storm", "Sunset Storm 壱", "Sunset Storm 弐",
    "Emerald Jörmungandr", "The Bronze", "Night Heist", "Hypnotic", "Golden Koi", "Cobalt Disruption", "Heirloom", "Conspiracy", "Crimson Web", "Naga", "Bronze Deco",
    "Corinthian", "Kumicho Dragon", "Directive", "Oxide Blaze", "Code Red", "Light Rail", "Blue Ply", "Trigger Discipline", "Ocean Drive",
    "Desert Tactical", "Ancient Visions", "Business Class", "Royal Blue", "Night Ops", "Road Rash", "Forest Leaves", "Check Engine", "Orange Anolis", "Purple DDPAT",
    "Whiteout", "Para Green", "Pathfinder", "Target Acquired", "Overgrowth", "Serum", "Stainless", "Orion", "Caiman",
    "Torque", "Kill Confirmed", "Lead Conduit", "Blueprint", "Cortex", "Monster Mashup", "The Traitor", "Black Lotus",
    "Ticket to Hell", "Ramese's Reach", "Death Rattle", "Reactor", "Brass", "Candy Apple", "Groundwater", "Synth Leaf", "High Beam",
    "Nuclear Garden", "Gamma Doppler", "Red Tire", "Twilight Galaxy", "Sand Dune", "Franklin", "Dragon Tattoo", "Blue Fissure", "Water Elemental",
    "Steel Disruption", "Grinder", "Catacombs", "Bunsen Burner", "Wraiths", "Royal Legion", "Weasel", "Ironwork", "Off World", "Moonrise",
    "Warhawk", "Sacrifice", "Bullet Queen", "Vogue", "Clear Polymer", "Snack Attack", "Winterized", "Umbral Rabbit", "Panther Camo",
    "Coach Class", "Chainmail", "Scorpion", "Amber Fade", "Granite Marbleized", "Grassland Leaves", "Silver", "Grassland", "Space Race", "Dispatch",
    "Ocean Foam", "Red FragCam", "Pulse", "Ivory", "Fire Elemental", "Handgun", "Imperial", "Oceanic", "Imperial Dragon", "Turf", "Woodsman",
    "Urban Hazard", "Obsidian", "Acid Etched", "Gnarled", "Lifted Spirits", "Wicked Sick", "Inheritance", "Chrome Cannon", "Jawbreaker", "Block-18", "Etch Lord", "Slag",  
]